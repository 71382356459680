import * as React from "react";

import {
  Button,
  Input,
  Modal,
  Table,
  Typography,
  message,
  Popconfirm,
  Tag,
  Divider,
  Form,
  Collapse,
} from "antd";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import {
  contractAddWebhookGQL,
  contractRemoveWebhookGQL,
} from "../../graphql/mutations.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import validator from "validator";
import HiddenText from "../elements/HiddenText";
import DeveloperFlightsAPIFieldSelect from "./DeveloperFlightsAPIFieldSelect.js";
import constants from "../../api/constants.js";

let example = JSON.stringify(
  {
    type: "flight.updated",
    timestamp: "2024-12-10T10:15:37.147Z",
    data: {
      flight: {
        id: "6757e647df71d22ab4b8a6eb",
        ctot: "2024-12-10T10:28:00.000Z",
        callsign: "ABC45EM",
        dep_icao: "LEMD",
        arr_icao: "EGLL",
        anyFlightDataYouWant: "you can look at all fields in the REST tab",
      },
      updatedFields: ["ctot"],
      indicators: {
        ctotIsNew: false,
        ctotHasChanged: true,
        ctotCanceled: false,
      },
    },
    req_id: "44683972-8eab-4763-bea8-110ade8fb3fe",
  },
  null,
  2
);

export default function DeveloperWebhooks({ contract }) {
  const [url, setUrl] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [fields, setFields] = React.useState([]);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  let [addWebhook] = useMutation(contractAddWebhookGQL, {
    refetchQueries: ["user"],
    onError: (err) =>
      message.error("Something went wrong \n" + JSON.stringify(err)),
  });

  let [removeWebhook] = useMutation(contractRemoveWebhookGQL, {
    refetchQueries: ["user"],
    onError: (err) =>
      message.error("Something went wrong \n" + JSON.stringify(err)),
  });

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleOk = () => {
    if (!validator.isURL(url, { protocols: ["https", "http"] }))
      return message.error("URL not valid.");

    if (_.isEmpty(fields)) return message.error("Select data fields");

    addWebhook({
      variables: { contractId: contract._id, url, description, fields },
    });

    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteWebhook = (data) => {
    removeWebhook({ variables: { contractId: contract._id, _id: data._id } });
  };

  const webhooks = _.get(contract, "webhooks", []);

  const columns = [
    {
      title: "URL endpoint",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "Data fields",
      dataIndex: "fields",
      render: (ele) => (
        <div>
          {_.map(ele, (key) => (
            <Tag key={key}>{key}</Tag>
          ))}
        </div>
      ),
      key: "fields",
      //width: 400,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 120,
    },
    {
      title: (
        <>
          Secure header key (
          <span style={{ fontStyle: "italic" }}>CTOT-Webhook-Key</span>)
        </>
      ),
      render: (ele) => <HiddenText value={ele.key} iconColor="#000" />,
      key: "key",
      width: 200,
    },
    {
      title: "Delete",
      key: "delete",
      width: 90,
      render: (ele) => (
        <Popconfirm
          title="Delete the endpoint"
          description="Are you sure to delete this endpoint?"
          onConfirm={() => deleteWebhook(ele)}
          okText="Yes"
          cancelText="No"
        >
          <FontAwesomeIcon
            icon={faTrashAlt}
            style={{ cursor: "pointer" }}
            className="hover-opacity"
          />
        </Popconfirm>
      ),
    },
  ];

  // Get webhook fields
  const [allFields, setAllFields] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);

    const url = constants.API_URL + "/rest/v1/flights/docs";
    fetch(url).then((response) =>
      response.json().then((docs) => {
        // Get the flight fields
        const allFields = _.chain(docs)
          .get("components.schemas.flight.properties")
          .keys()
          //.map((key) => ({ label: key, value: key }))
          .value();

        setAllFields(allFields);
        setLoading(false);
      })
    );
  }, []);

  return (
    <>
      <Modal
        title="Add a new endpoint"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add endpoint"
      >
        <strong>URL endpoint</strong>
        <Input
          value={url}
          onChange={(event) => setUrl(event.target.value)}
          placeholder="https://"
        />
        <br />
        <br />

        <strong>Flight data fields sent to endpoint</strong>
        <DeveloperFlightsAPIFieldSelect setFields={setFields} />
        <br />
        <br />

        <strong>Description</strong>
        <Input
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          placeholder="Add a description"
        />
        <br />
        <br />
      </Modal>
      <div className="">
        <strong>Webhooks</strong> allow you to create custom workflows by
        sending real-time notifications to your server whenever there’s a flight
        data change, for example a new CTOT. While webhooks aren’t necessary for
        using the public app, they’re invaluable for advanced use cases, such as
        sending notifications through your own channels, servers, or custom
        apps.
        <Divider />
        {!contract.flightApi && (
          <>
            <strong>Use Cases</strong>
            <br />
            Webhooks are perfect for scenarios where you need to:{" "}
            <ul>
              <li>
                Send flight updates through custom communication channels.
              </li>
              <li>
                Integrate CTOT notifications into your proprietary systems.
              </li>
              <li>Automate workflows triggered by flight schedule changes.</li>
            </ul>
            <Divider />
          </>
        )}

        <strong>Data Format</strong>
        <br />
        The data is sent as JSON. The HTTP header includes your{" "}
        <div className="code">CTOT-Webhook-Key</div> for authentication.
        <Divider />

        <strong>Data Provided</strong>
        <br />
        The flight data is based on the <strong>REST</strong> api. Take a look
        at the <strong>REST</strong> tab to find out more.
        <br />
        The webhook notifications also contain the list of updated fields
        compared to the previous flight data sent to you.
        <br />
        In addition, we also include some indicators to help you identify
        important changes such as CTOT changes or if the flight is a new one
        (i.e. this is the first notification about that flight).
        <Collapse>
          <Collapse.Panel header="View all fields you can request">
            <pre>{JSON.stringify(allFields, null, 2)}</pre>
          </Collapse.Panel>
          <Collapse.Panel header="View an example webhook notification">
            <pre>{example}</pre>
          </Collapse.Panel>
        </Collapse>
        <Divider />
        {contract.flightApi && (
          <>
            <strong>Manage Your Endpoints</strong>
            <br />
            A webhook endpoint is a URL where CTOT sends data about flights
            you’re tracking. You can use these endpoints to receive updates
            about subscribed flights. By default, all flights within your
            organization are tracked.
            <br />
            <br />
            <Button onClick={showModal} type="primary">
              <FontAwesomeIcon icon={faPlusSquare} /> &nbsp; Add a new endpoint
            </Button>
            <br />
            <br />
            <Table
              dataSource={webhooks}
              columns={columns}
              pagination={false}
              scroll={{ y: "max-content" }}
              tableLayout="auto"
            />
          </>
        )}
      </div>
      <br />
      <br />
    </>
  );
}
